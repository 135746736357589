export default class SourceCodeRegion {

  constructor(configuration, page) {

    this.page = page;
    this.configuration = configuration;
    this.container = null;

    this.userId = this.page.getUserId();
    this.authorizationToken = this.page.getAuthorizationToken();

  }

  async initialize() {

  }

}